<template>
  <a-modal
    :visible="visible"
    title="调整面试邀请"
    :footer="null"
    @cancel="cancel"
  >
    <div class="title" v-if="user.name">
      {{ `确认调整${user.name}的面试吗？` }}
    </div>

    <a-form
      :form="form"
      :colon="false"
      @submit="handleSubmit"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-item label="面试方式">
        <a-radio-group
          v-decorator="[
            'method',
            {
              rules: [{ required: true, message: '请选择！' }],
            },
          ]"
        >
          <a-radio
            v-for="item in interviewMethodList"
            :key="item.value"
            :value="item.value"
            >{{ item.name }}</a-radio
          >
        </a-radio-group>
      </a-form-item>

      <a-form-item
        label="面试地点"
        v-if="form.getFieldValue('method') === 'offline'"
      >
        <a-select
          v-decorator="[
            'roomId',
            {
              initialValue: interview.roomId,
              rules: [{ required: true, message: '请选择！' }],
            },
          ]"
        >
          <a-select-option
            v-for="item in meetingRoomList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>

      <a-form-item
        label="腾讯会议号"
        v-if="form.getFieldValue('method') === 'online'"
      >
        <a-input
          v-decorator="[
            'onlineRoomId',
            {
              initialValue: interview.onlineRoomId,
              rules: [{ required: true, message: '请输入！' }],
            },
          ]"
        />
      </a-form-item>

      <a-form-item label="面试时间">
        <a-date-picker
          placeholder=""
          format="YYYY-MM-DD HH:mm"
          :showTime="{
            format: 'HH:mm',
          }"
          v-decorator="[
            'interviewDate',
            {
              initialValue: moment2(interview.adjustInterviewDate),
              rules: [{ required: true, message: '请选择！' }],
            },
          ]"
          style="width: 100%"
        />
      </a-form-item>

      <a-form-item label="调整说明">
        <a-textarea
          :auto-size="{ minRows: 3 }"
          :disabled="true"
          :value="interview.adjustContent"
        />
      </a-form-item>

      <a-form-item label="意见">
        <a-textarea
          :auto-size="{ minRows: 3 }"
          v-decorator="[
            'adjustAnswer',
            {
              rules: [{ required: true, message: '请输入！' }],
            },
          ]"
        />
      </a-form-item>

      <div class="right">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" html-type="submit" :loading="loading">
            发送
          </a-button>
        </a-space>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchList } from "@/api/meeting";
import { changeInterview } from "@/api/recruit";
import moment from "moment";
export default {
  props: {
    visible: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      meetingRoomList: [],
      loading: false,
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        this.form.setFieldsValue({
          method: this.interview.adjustMethod,
        });

        fetchList({
          pageNum: 1,
          pageSize: 100,
        }).then((res) => {
          if (Array.isArray(res.list)) {
            this.meetingRoomList = res.list;
          }
        });
      }
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    // 面试方式
    interviewMethodList() {
      return this.findDataDict("interviewMethod");
    },
    interview() {
      return this.user.interview ? this.user.interview : {};
    },
  },

  methods: {
    moment2(value) {
      return moment(value);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          let interviewAddress;

          if (values.roomId) {
            const room = this.meetingRoomList.find(
              (item) => item.id === values.roomId
            );
            interviewAddress = room ? room.name : undefined;
          }

          this.loading = true;

          changeInterview({
            pid: this.interview.pid,
            ...values,
            interviewAddress,
            interviewDate: values.interviewDate.format("YYYY-MM-DD HH:mm"),
          })
            .then(() => {
              this.cancel();
              this.$emit("refresh");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    cancel() {
      this.form.resetFields();
      this.$emit("setVisible", false);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 12px;
}
</style>