<template>
  <span>
    <input ref="uploader" id="uploader" type="file" @change="onChange" />
    <a-button
      icon="folder-open"
      type="primary"
      shape="round"
      @click="onClick"
      :loading="loading"
    >
      批量导入</a-button
    >
  </span>
</template>

<script>
import { importFile } from "@/api/recruit";
export default {
  props: ["deliveryChannel"],
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    onClick() {
      this.$refs.uploader.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      data.append("deliveryChannel", this.deliveryChannel);
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.loading = true;

      importFile(data)
        .then(() => {
          this.$emit("refresh");
        })
        .catch((err) => {
          console.log("err", err);
          if (err.message) {
            this.$error({
              title: "提示",
              content: err.message,
            });
          }
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },
  },
};
</script>

<style lang="less" scoped>
#uploader {
  display: none;
}
</style>