<template>
  <a-tooltip title="修改" v-if="$getPermission($route.path + '/edit')">
    <a href="#" @click.prevent="visible = true">
      <a-icon type="edit" />
    </a>

    <a-modal
      :visible="visible"
      title="修改邀约状态"
      @ok="ok"
      @cancel="cancel"
      :okButtonProps="{
        props: {
          loading,
        },
      }"
      width="340px"
    >
      <a-select
        style="width: 100%"
        v-model="invitationStatus"
        placeholder="请选择邀约状态"
      >
        <a-select-option
          v-for="item in invitationStatusList"
          :key="item.value"
          :value="item.value"
          >{{ item.name }}</a-select-option
        >
      </a-select>

      <a-input
        style="margin-top: 12px"
        placeholder="请输入其他"
        v-if="invitationStatus === 'career_invitation_status_other'"
        v-model="invitationContent"
      />
    </a-modal>
  </a-tooltip>
</template>

<script>
import { changeStatus } from "@/api/recruit";
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    invitationStatusList() {
      return this.findDataDict("career_invitation_status");
    },
  },

  data() {
    return {
      visible: false,
      invitationStatus: "",
      invitationContent: "",
      loading: false,
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.invitationStatus = this.item.invitationStatus;
        this.invitationContent = this.item.invitationContent;
      }
    },
  },

  methods: {
    cancel() {
      this.visible = false;
    },
    ok() {
      if (this.invitationStatus) {
        this.loading = true;
        changeStatus({
          id: this.item.id,
          invitationStatus: this.invitationStatus,
          invitationContent: this.invitationContent,
        })
          .then(() => {
            this.visible = false;
            this.$emit("refresh");
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$message.error("请选择");
      }
    },
  },
};
</script>