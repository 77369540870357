<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        :tabBarStyle="{
          margin: '0',
        }"
        v-model="active"
      >
        <a-tab-pane
          v-for="item in career_delivery_channel"
          :key="item.value"
          :tab="item.name"
        >
        </a-tab-pane>
      </a-tabs>
    </Pane>

    <a-card class="container">
      <a-form-model
        :model="form"
        :colon="false"
        layout="inline"
        @keyup.enter.native="query"
      >
        <a-form-model-item>
          <a-input
            placeholder="姓名"
            v-model="form.name"
            style="width: 150px"
          />
        </a-form-model-item>
        <a-form-item>
          <a-select
            placeholder="性别"
            v-model="form.sex"
            style="width: 150px"
          >
            <a-select-option
                v-for="item in genderList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-model-item>
          <a-input
            v-if="active === 'school_enrollment'"
            placeholder="应届年份"
            v-model="form.freshYear"
            style="width: 150px"
          />
        </a-form-model-item>
        <a-form-model-item label="">
          <a-input
            placeholder="联系方式"
            v-model="form.mobile"
            style="width: 150px"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            placeholder="是否应届"
            v-model="form.isFresh"
            style="width: 150px"
          >
            <a-select-option
              v-for="item in [
                { name: '是', value: 1 },
                { name: '否', value: 0 },
              ]"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-input
            placeholder="面试岗位"
            v-model="form.post"
            style="width: 150px"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-select
            placeholder="学历"
            v-model="form.degree"
            style="width: 150px"
          >
            <a-select-option
              v-for="item in degreeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-date-picker
            style="width: 150px"
            placeholder="面试时间"
            v-model="form.interviewDate"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-select
            placeholder="面试状态"
            v-model="form.resultStatus"
            style="width: 150px"
          >
            <a-select-option
              v-for="item in resultStatusList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-select
            placeholder="邀约状态"
            v-model="form.invitationStatus"
            style="width: 150px"
          >
            <a-select-option
              v-for="item in invitationStatusList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-select
            placeholder="应聘者确认"
            v-model="form.confirmStatus"
            style="width: 150px"
          >
            <a-select-option
              v-for="item in confirmStatusList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-range-picker
            :placeholder="['开始投递时间', '结束投递时间']"
            v-model="form.deliveryTime"
            style="width: 316px"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-range-picker
            :placeholder="['简历下载时间', '简历下载时间']"
            v-model="form.downloadTime"
            style="width: 316px"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <Padding size="large" />

      <Invite
        :user="selectedUser"
        :userNames = "selectedUserName"
        :keys="selectedRowKeys"
        :visible="inviteVisible"
        @setVisible="(visible) => (inviteVisible = visible)"
        @refresh="getList"
      />

      <Hire
        :user="selectedUser"
        :visible="hireVisible"
        @setVisible="(visible) => (hireVisible = visible)"
        @refresh="getList"
      />

      <Adjust
        :user="selectedUser"
        :visible="adjustVisible"
        @setVisible="(visible) => (adjustVisible = visible)"
        @refresh="getList"
      />

      <div class="between">
        <a-space>
          <a-button
            icon="user"
            type="primary"
            shape="round"
            @click="batchInvite"
            v-if="$getPermission($route.path + '/invite')"
            >面试邀请</a-button
          >
          <a-button
            icon="user"
            type="primary"
            shape="round"
            style="
              background-color: rgb(122, 119, 226);
              border-color: rgb(122, 119, 226);
            "
            @click="inviteHR"
            v-if="$getPermission($route.path + '/invite')"
            >邀请面试官</a-button
          >

          <a-button
            icon="download"
            type="primary"
            shape="round"
            style="background-color: #52c41a; border-color: #52c41a"
            @click="onDownloadResume"
            v-if="$getPermission($route.path + '/add')"
            :loading="downloading"
            >简历下载</a-button
          >

          <a-button
            icon="user"
            type="danger"
            shape="round"
            @click="batchOut"
            v-if="$getPermission($route.path + '/hire')"
            >未录用</a-button
          >
          <a-button
            icon="delete"
            type="primary"
            shape="round"
            style="background-color: #5c5c5c; border-color: #5c5c5c"
            @click="batchDelete"
            v-if="$getPermission($route.path + '/delete')"
            >删除</a-button
          >
        </a-space>
        <a-space>
          <Search @search="onSearch" />
          <a-button
            icon="file-add"
            type="primary"
            shape="round"
            style="background-color: #ff8c18; border-color: #ff8c18"
            v-if="
              active !== 'school_enrollment' &&
              $getPermission($route.path + '/add')
            "
            @click="$router.push($route.path + '/add')"
          >
            新建人员</a-button
          >
          <UserInfoUploader
            :deliveryChannel="active"
            v-if="$getPermission($route.path + '/import')"
            @refresh="getList"
          />

          <DownloadTable :ids="selectedRowKeys" />

          <a-button
            icon="file-add"
            type="primary"
            shape="round"
            style="background-color: #5c5c5c; border-color: #5c5c5c"
            v-if="$getPermission($route.path + '/import')"
            @click="
              open(
                'https://s.upapi.cn/njszy/2022/6/8/babbbfd181adf16a6cce7a230ab89f80_招聘导入模板.xlsx'
              )
            "
          >
            下载模板</a-button
          >
        </a-space>
      </div>

      <Padding size="large" />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        :row-selection="{
          selectedRowKeys,
          onChange: onSelectChange,
          onSelect: onSelect,
          onSelectAll: onSelectAll
        }"
        :scroll="scroll"
      >
        <a-table-column title="姓名" align="center">
          <template slot-scope="text">
            <a
              href="#"
              v-if="$getPermission($route.path + '/edit')"
              @click.prevent="$router.push($route.path + '/edit?id=' + text.id)"
              :class="renderName(text.sex)"
              >{{ text.name }}</a
            >
            <a href="#" v-else @click.prevent :class="renderName(text.sex)">{{
              text.name
            }}</a>
          </template>
        </a-table-column>
        <a-table-column v-if="active === 'school_enrollment'" title="应届年份" align="center" data-index="freshYear" />
        <a-table-column title="联系方式" align="center" data-index="mobile" />

        <a-table-column title="最高学历" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="degree" :dictValue="text.degree" />
          </template>
        </a-table-column>
        <a-table-column title="毕业学校" align="center" data-index="schools" />
        <a-table-column title="是否应届" align="center">
          <template slot-scope="text">
            <span v-if="text.isFresh === 1">是</span>
            <span v-if="text.isFresh === 0">否</span>
          </template>
        </a-table-column>

        <a-table-column
          title="是否服从调剂"
          align="center"
          v-if="active === 'school_enrollment'"
        >
          <template slot-scope="text">
            <span v-if="text.isObeyDispensing === 1">是</span>
            <span v-if="text.isObeyDispensing === 0">否</span>
          </template>
        </a-table-column>

        <a-table-column
          title="投递时间"
          align="center"
          data-index="deliveryTime"
        />

        <a-table-column
          :title="active === 'school_enrollment' ? '投递岗位' : '面试岗位'"
          align="center"
          data-index="post"
        />
        <a-table-column title="面试部门" align="center" data-index="deptName" />

        <a-table-column title="面试方式" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="interviewMethod"
              :dictValue="text.interview ? text.interview.method : ''"
            />
          </template>
        </a-table-column>
        <a-table-column
          title="面试时间"
          align="center"
          data-index="interviewDate"
        />

        <a-table-column title="应聘者确认" align="center">
          <template slot-scope="text">
            <div class="confirm-status">
              <DataDictFinder
                dictType="confirmStatus"
                :dictValue="text.confirmStatus"
              />
              <!-- 用户申请调整，确认状态变成调整中，管理员可以通过调整 -->
              <a-icon
                type="edit"
                class="icon-adjust"
                v-if="text.confirmStatus === 'adjusted'"
                @click="adjust(text)"
              />
            </div>
          </template>
        </a-table-column>

        <a-table-column title="面试状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="resultStatus"
              :dictValue="text.resultStatus"
            />
            <a href="#"  v-if="text.interview && text.interview.id" @click.prevent="queryMessage(text)">
              查询短信状态
            </a>
          </template>
        </a-table-column>

        <a-table-column title="邀约状态" align="center">
          <template slot-scope="text">
            <div class="center invitation-status">
              <DataDictFinder
                dictType="career_invitation_status"
                :dictValue="text.invitationStatus"
              />
              <span
                v-if="
                  text.invitationStatus === 'career_invitation_status_other'
                "
                style="padding-left: 4px"
              >
                ({{ text.invitationContent }})
              </span>
              <div class="edit-invitation-status">
                <EditInvitationStatus :item="text" @refresh="getList" />
              </div>
            </div>
          </template>
        </a-table-column>

        <a-table-column align="center" title="操作" width="200px" fixed="right">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/resume')"
                @click.prevent="
                  $router.push($route.path + '/resume?id=' + text.id)
                "
                >简历</a
              >
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="
                  $router.push($route.path + '/edit?id=' + text.id)
                "
                >编辑</a
              >
              <a
                href="#"
                @click.prevent="invite(text)"
                v-if="$getPermission($route.path + '/invite')"
                >邀请面试</a
              >
              <a
                href="#"
                @click.prevent="hireUser(text)"
                v-if="$getPermission($route.path + '/hire')"
                >发offer</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import screen from "@/mixins/screen";
import watermark from "@/mixins/watermark";
import { fetchList, out, remove, downloadResume, queryMsg } from "@/api/recruit";
import { mapGetters } from "vuex";
import Search from "./components/search.vue";
import UserInfoUploader from "./components/user-info-uploader.vue";
import Invite from "./components/invite.vue";
import Hire from "./components/hire.vue";
import Adjust from "./components/adjust.vue";
import EditInvitationStatus from "./components/edit-invitation-status.vue";
import DownloadTable from "./components/download-table.vue";
import { saveAs } from "file-saver";
import moment from "moment";
export default {
  name: "recruit",

  mixins: [screen, watermark],

  components: {
    Search,
    UserInfoUploader,
    Invite,
    Hire,
    Adjust,
    EditInvitationStatus,
    DownloadTable,
  },

  data() {
    return {
      active: null,
      form: {},
      searchValues: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      selectedRowKeys: [],
      selectedUser: {},
      selectedUserName: [],
      selectionRows: [],
      inviteVisible: false, // 面试邀请弹窗

      hireVisible: false, // 发 offer 弹窗

      adjustVisible: false, // 管理员确认用户调整弹窗

      selectedNames: [],
      downloading: false,

      downloadTableVisible: false, // 批量导出弹窗显示隐藏
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    career_delivery_channel() {
      return this.findDataDict("career_delivery_channel");
    },
    // 学历
    degreeList() {
      return this.findDataDict("degree");
    },
    // 面试状态
    resultStatusList() {
      return this.findDataDict("resultStatus");
    },
    confirmStatusList() {
      return this.findDataDict("confirmStatus");
    },
    invitationStatusList() {
      return this.findDataDict("career_invitation_status");
    },
    genderList() {
      return this.findDataDict("sex");
    },
  },

  watch: {
    active() {
      this.getList();
    },
  },

  mounted() {
    this.active = this.career_delivery_channel[0].value;
  },

  methods: {
    getList() {
      const { current, pageSize, active, form, searchValues } = this;
      this.loading = true;
      let deliveryTimeStart, deliveryTimeEnd;
      if (Array.isArray(form.deliveryTime) && form.deliveryTime.length === 2) {
        deliveryTimeStart = form.deliveryTime[0].format("YYYY-MM-DD");
        deliveryTimeEnd = form.deliveryTime[1].format("YYYY-MM-DD");
      }
      let downloadTimeEnd, downloadTimeStart;

      if (Array.isArray(form.downloadTime) && form.downloadTime.length === 2) {
        downloadTimeStart = form.downloadTime[0].format("YYYY-MM-DD");
        downloadTimeEnd = form.downloadTime[1].format("YYYY-MM-DD");
      }

      fetchList({
        pageNum: current,
        pageSize,
        deliveryChannel: active,
        ...form,
        interviewDate: form.interviewDate
          ? form.interviewDate.format("YYYY-MM-DD")
          : undefined,

        deliveryTime: undefined,
        deliveryTimeStart,
        deliveryTimeEnd,

        downloadTimeStart,
        downloadTimeEnd,
        downloadTime: undefined,

        ...searchValues,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = res.list.map((item) => {
              return {
                ...item,
                key: item.id,
              };
            });
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },
    onSelect(record, selected) {
      if (selected) {
        this.selectionRows.push(record)
      } else {
        const delIndex = this.selectionRows.findIndex(val => {
            return val.id === record.id
        })
        this.selectionRows.splice(delIndex, 1)
      }
      this.selectedNames = this.selectionRows.map(item => item.name)
    },
    onSelectAll(selected, selectionRows, changeRows) {
      if (selected) {
        this.selectionRows = this.selectionRows.concat(changeRows)
      }
      if (!selected) {
        let selectionRows = JSON.parse(JSON.stringify(this.selectionRows))
        const delIndex = []
        selectionRows.forEach((item, index) => {
          changeRows.forEach(val => {
            if (item.id === val.id) {
              delIndex.push(index)
            }
          })
        })
        delIndex.forEach(item => {
          delete selectionRows[item]
        })
        selectionRows = selectionRows.filter(item => {
          return item !== undefined
        })
        this.selectionRows = selectionRows
      }
      this.selectedNames = this.selectionRows.map(item => item.name)
    },
    query() {
      this.searchValues = {};
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.searchValues = {};
      this.current = 1;
      this.getList();
    },

    onSearch(payload) {
      this.form = {};
      this.searchValues = payload;
      this.current = 1;
      this.getList();
    },

    // 批量邀请
    batchInvite() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请选择人员");
        return;
      }
      this.selectedUser = {};
      this.selectedUserName = this.selectedNames;
      this.inviteVisible = true;
    },

    // 单一邀请
    invite(text) {
      this.selectedRowKeys = [];
      this.selectedNames = [];
      this.selectionRows = [];
      this.selectedUser = text;
      this.selectedUserName = [];
      this.inviteVisible = true;
    },

    // 批量未录用
    batchOut() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请选择人员");
        return;
      }

      const that = this;
      this.$confirm({
        title: "确定要全部退回吗？",
        onOk() {
          out(that.selectedRowKeys).then(() => {
            that.selectedRowKeys = [];
            that.getList();
          });
        },
      });
    },
    // 批量删除
    batchDelete() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请选择人员");
        return;
      }

      const that = this;
      this.$confirm({
        title: `是否删除当前${this.selectedRowKeys.length}名人员？`,
        onOk() {
          remove(that.selectedRowKeys).then(() => {
            that.selectedRowKeys = [];
            that.getList();
          });
        },
      });
    },
    // 发 offer
    hireUser(text) {
      console.log("text", text);
      this.selectedUser = text;
      this.hireVisible = true;
    },

    // 用户发来了调整申请，我来确认是否使用用户改的数据，如果不使用
    adjust(text) {
      this.selectedUser = text;
      this.adjustVisible = true;
    },

    // 邀请面试官
    inviteHR() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请选择人员");
        return;
      }
      this.$router.push(
        this.$route.path + "/invite-hr?ids=" + this.selectedRowKeys.join()
      );
    },

    renderName(gender) {
      if (gender === "Male") {
        return "male";
      } else if (gender === "FeMale") {
        return "female";
      } else {
        return "unknown";
      }
    },

    open(url) {
      window.open(url);
    },

    onDownloadResume() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请选择人员");
        return;
      }

      this.downloading = true;
      downloadResume(this.selectedRowKeys)
        .then((blob) => {
          const arr = [...new Set(this.selectedNames)];
          const str =
            arr.length > 3
              ? arr.filter((item, index) => index < 3).join(",") +
                "等" +
                arr.length +
                "人的简历"
              : arr.join(",") + "-" + arr.length + "人的简历";
          saveAs(blob, `${str}.zip`);
        })
        .finally(() => {
          this.downloading = false;
        });
    },

    queryMessage(text) {
      const hide = this.$message.loading("查询中...")
      queryMsg({
        bizId: text.interview.id,
        date: moment().format('YYYYMMDD'),
        mobile: text.mobile,
      }).then(res => {
        console.log('res',res)
        // if (res.sendDate) {
          this.$message.success({
            content: res.msg,
            duration: 5
          })
        // }
      }).finally(()=> {
        hide();
      })
    }
  },
};
</script>


<style lang="less" scoped>
.male {
  color: #1890ff;
}
.female {
  color: #ff1493;
}
.unknown {
  color: #222;
}
.confirm-status {
  position: relative;

  .icon-adjust {
    position: absolute;
    top: 3px;
    right: 0;
    color: #1890ff;
  }
}

.invitation-status {
  position: relative;
  .edit-invitation-status {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}
</style>