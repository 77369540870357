<template>
  <div>
    <a-button
      icon="file-search"
      type="primary"
      shape="round"
      style="background-color: #52c41a; border-color: #52c41a"
      @click="visible = true"
      >高级搜索</a-button
    >

    <a-modal
      :visible="visible"
      title="高级搜索"
      @cancel="cancel"
      :footer="null"
      width="800px"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="性别">
              <a-radio-group v-decorator="['sex']">
                <a-radio
                  v-for="item in genderList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="面试阶段">
              <a-select v-decorator="['phase']">
                <a-select-option
                  v-for="item in phaseList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="出生日期">
              <a-range-picker
                :mode="['year', 'year']"
                :placeholder="['开始日期', '结束日期']"
                format="YYYY"
                style="width: 100%"
                :value="birthday"
                @panelChange="handleBirthDayPanelChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="应聘者确认">
              <a-select v-decorator="['confirmStatus']">
                <a-select-option
                  v-for="item in confirmStatusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="是否离职">
              <a-radio-group v-decorator="['isDeparture']">
                <a-radio
                  v-for="item in booleanList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="面试状态">
              <a-select v-decorator="['resultStatus']">
                <a-select-option
                  v-for="item in resultStatusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="到岗时间">
              <a-select v-decorator="['arrivalTime']">
                <a-select-option
                  v-for="item in workTimeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="面试时间">
              <a-date-picker
                style="width: 100%"
                placeholder=""
                v-decorator="['interviewDate']"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="应聘部门">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-decorator="['deptId']"
                style="width: 100%"
                placeholder=""
              >
                <a-select-option
                  v-for="item in productionDepartmentList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="投递时间">
              <a-range-picker
                style="width: 100%"
                v-decorator="['deliveryTime']"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="应聘岗位">
              <a-input v-decorator="['post']" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="婚育状况">
              <a-select v-decorator="['maritalStatus']">
                <a-select-option
                  v-for="item in marrigeStatusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="期望薪资" style="margin-bottom: 0">
              <a-form-item style="width: 40%; display: inline-block">
                <a-input-number
                  v-decorator="['salaryMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span style="width: 5%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 40%; display: inline-block">
                <a-input-number
                  v-decorator="['salaryMax']"
                  style="width: 100%"
                />
              </a-form-item>

              <span style="width: 15%; display: inline-block; text-align: right"
                >万/年</span
              >
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="籍贯">
              <a-input v-decorator="['nativePlace']" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="最高学历" style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-select v-decorator="['degreeMin']" style="width: 100%">
                  <a-select-option
                    v-for="item in degreeList"
                    :key="item.value"
                    :value="item.value"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-select v-decorator="['degreeMax']" style="width: 100%">
                  <a-select-option
                    v-for="item in degreeList"
                    :key="item.value"
                    :value="item.value"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="英语水平">
              <a-select v-decorator="['englishLevelMin']">
                <a-select-option
                  v-for="item in englishLevelList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="毕业院校">
              <a-input v-decorator="['schools']" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="计算机水平">
              <a-select v-decorator="['computerLevelMin']">
                <a-select-option
                  v-for="item in computerLevelList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">搜索</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>


<script>
import organization from "@/mixins/organization";
import { mapGetters } from "vuex";
export default {
  mixins: [organization],

  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      birthday: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    genderList() {
      return this.findDataDict("sex");
    },
    // 面试阶段
    phaseList() {
      return this.findDataDict("recruit_phase");
    },
    // 应聘者确认状态
    confirmStatusList() {
      return this.findDataDict("confirmStatus");
    },
    booleanList() {
      return this.findDataDict("boolean");
    },
    // 到岗时间
    workTimeList() {
      return this.findDataDict("workTime");
    },
    // 面试结果
    resultStatusList() {
      return this.findDataDict("resultStatus");
    },
    // 婚姻状态
    marrigeStatusList() {
      return this.findDataDict("marrigeStatus");
    },
    // 学历
    degreeList() {
      return this.findDataDict("degree");
    },
    // 英语水平
    englishLevelList() {
      return this.findDataDict("englishLevel");
    },
    // 计算机水平
    computerLevelList() {
      return this.findDataDict("computerLevel");
    },
  },

  methods: {
    handleBirthDayPanelChange(value) {
      this.birthday = value;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          let birthdayMin, birthdayMax, deliveryTimeStart, deliveryTimeEnd;

          if (this.birthday && this.birthday.length === 2) {
            birthdayMin = this.birthday[0].format("YYYY");
            birthdayMax = this.birthday[1].format("YYYY");
          }

          if (values.deliveryTime && values.deliveryTime.length === 2) {
            deliveryTimeStart = values.deliveryTime[0].format("YYYY-MM-DD");
            deliveryTimeEnd = values.deliveryTime[1].format("YYYY-MM-DD");
          }

          this.$emit("search", {
            ...values,
            birthdayMin,
            birthdayMax,
            deliveryTimeStart,
            deliveryTimeEnd,
            deliveryTime: undefined,

            interviewDate: values.interviewDate
              ? values.interviewDate.format("YYYY-MM-DD")
              : undefined,
          });
          this.cancel();
        }
      });
    },

    cancel() {
      this.visible = false;
    },
  },
};
</script>