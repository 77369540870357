<template>
  <span>
    <a-button
      icon="download"
      type="primary"
      shape="round"
      style="
        background-color: rgb(122, 119, 226);
        border-color: rgb(122, 119, 226);
      "
      @click="onClick"
      v-if="$getPermission($route.path + '/import')"
      >批量导出</a-button
    >

    <a-modal
      title="选择要导出的字段"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
    >
      <a-table
        bordered
        :data-source="list"
        :pagination="false"
        rowKey="title"
        :row-selection="{
          selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <a-table-column title="字段名" data-index="title" align="center">
        </a-table-column>
      </a-table>

      <Padding />
      <Padding />
      <div class="right">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button type="primary" @click="save" :loading="loading"
            >导出</a-button
          >
        </a-space>
      </div>
    </a-modal>
  </span>
</template>

<script>
import download from "@/api/download";
import { saveAs } from "file-saver";
export default {
  props: {
    ids: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      visible: false,
      list: [
        {
          title: "序号",
        },
        {
          title: "姓名",
        },
        {
          title: "电话",
        },
        {
          title: "性别",
        },
        {
          title: "出生年月",
        },
        {
          title: "是否应届",
        },
        {
          title: "是否服从调剂",
        },
        {
          title: "投递时间",
        },
        {
          title: "本科（学校）",
        },
        {
          title: "本科专业",
        },
        {
          title: "硕士（学校）",
        },
        {
          title: "硕士专业",
        },
        {
          title: "博士（学校）",
        },
        {
          title: "博士专业",
        },
        {
          title: "投递岗位",
        },
        {
          title: "面试时间",
        },
        {
          title: "面试方式",
        },
      ],
      selectedRowKeys: [
        "序号",
        "姓名",
        "性别",
        "出生年月",
        "本科（学校）",
        "本科专业",
        "硕士（学校）",
        "硕士专业",
        "博士（学校）",
        "博士专业",
        "投递岗位",
      ],
      loading: false,
    };
  },

  methods: {
    onClick() {
      if (this.ids.length === 0) {
        this.$message.error("请选择人员");
        return;
      }

      this.visible = true;
    },
    cancel() {
      this.visible = false;
    },

    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },
    save() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请选择字段");
        return;
      }

      this.loading = true;
      download({
        url: "/office-service/career/resume/export",
        method: "post",
        data: {
          colList: this.selectedRowKeys,
          idList: this.ids,
        },
      })
        .then((blob) => {
          this.cancel();
          saveAs(blob, `${this.ids.length}个人信息.xlsx`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>